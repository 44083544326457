<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../../../assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
      data-black-overlay="5"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="breadcrumb-inner text-center pt--100">
              <h2 class="heading-title theme-gradient">
                portfolio.
              </h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="inner">
                <h2>Best Future Homes</h2>
                <p class="subtitle">
                  Best Future Homes is a custom home builder.
                </p>
                <p>
                  They take on some serious projects - 
                  their most recent focus is on building what they call 'indestructible homes'. 
                  Our goal was to show off the model home and its features through a new website and graphics work.
                </p>
                <div class="portfolio-view-list d-flex flex-wrap">
                  <div class="port-view">
                    <span>Project Type</span>
                    <h4>Website, Graphic Design, Branding</h4>
                  </div>
                </div>
                <div class="portfolio-share-link mt--20 pb--70 pb_sm--40">
                  <!-- <ul
                    class="social-share rn-lg-size d-flex justify-start liststyle mt--15"
                  >
                    <li v-for="(social, i) in socialList" :key="i">
                      <a
                        target="_blank"
                        :href="social.url"
                        v-html="iconSvg(social.icon)"
                      ></a>
                    </li>
                  </ul> -->
                </div>
              </div>
              <div class="portfolio-thumb-inner">
                <div class="thumb thumbnail mb--30">
                  <img
                    src="../../../assets/images/portfolio/bestfuturehomes/1.jpg"
                    alt="Portfolio Images"
                  />
                </div>
                <div class="thumb thumbnail mb--30">
                  <img
                    src="../../../assets/images/portfolio/bestfuturehomes/2.jpg"
                    alt="Portfolio Images"
                  />
                </div>
                <div class="thumb thumbnail mb--30">
                  <img
                    src="../../../assets/images/portfolio/bestfuturehomes/3.jpg"
                    alt="Portfolio Images"
                  />
                </div>
                <div class="thumb thumbnail mb--30">
                  <img
                    src="../../../assets/images/portfolio/bestfuturehomes/4.jpg"
                    alt="Portfolio Images"
                  />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../../../components/header/Header";
  import Footer from "../../../components/footer/Footer";
  import feather from "feather-icons";

  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        socialList: [
          {
            url: "https://www.facebook.com/",
            icon: "facebook",
          },
          {
            url: "https://www.linkedin.com/",
            icon: "linkedin",
          },
          {
            url: "https://instagram.com/",
            icon: "instagram",
          },
          {
            url: "https://twitter.com/",
            icon: "twitter",
          },
        ],
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "Portfolio",
            to: "/portfolio",
            disabled: false,
          },
          {
            text: "Best Future Homes",
            to: "",
            disabled: true,
          },
        ],
        index: null,
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
